import NavigationLinkProvider from '@pretto/bricks/components/utility/NavigationLinkProvider'
import ConsentManager from '@pretto/bricks/shared/components/ConsentManager'

import { Script } from 'gatsby'
import PropTypes from 'prop-types'
import { createContext, memo, useMemo, useRef, useState } from 'react'

import { useTracking } from '../../utilities/tracking'
import { NavigationLink } from '../NavigationLink'

export const ConsentManagerContext = createContext({})

const ConsentManagerView = ({ location, getCookieBarProps }) => {
  const trackAction = useTracking()

  const navigationLinkProps = useRef({ linkComponent: NavigationLink, location }).current

  const onCookieAccepted = () => trackAction('COOKIE_BANNER_ACCEPTED')
  const onCookieCustomized = () => trackAction('COOKIE_BANNER_CUSTOMIZED')
  const onCookieRefused = () => trackAction('COOKIE_BANNER_REFUSED')

  const [isCookieBannerActive, setIsCookieBannerActive] = useState(false)

  const onClose = () => setIsCookieBannerActive(false)
  const onShowCookieBanner = () => setIsCookieBannerActive(true)

  const consentManager = useMemo(
    () => (
      <ConsentManager
        apiWriteKey={SEGMENT_KEY}
        onShowCookieBanner={onShowCookieBanner}
        onClose={onClose}
        onCookieAccepted={onCookieAccepted}
        onCookieCustomized={onCookieCustomized}
        onCookieRefused={onCookieRefused}
        isCookieBannerActive={isCookieBannerActive}
        getCookieBarProps={getCookieBarProps}
      />
    ),
    [isCookieBannerActive]
  )

  return (
    <>
      <Script
        id="segment-snippet"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";}}();`,
        }}
      />
      <Script
        id="matomo-snippet"
        dangerouslySetInnerHTML={{
          __html: `var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function() {var u="https://pretto.matomo.cloud/";_paq.push(['setTrackerUrl', u+'matomo.php']);_paq.push(['setSiteId', '5']);var d=document;d.cookie.split(";").reduce((t,r)=>{var e=r.trim();if(!e.startsWith("AB__"))return t;var i,a=e.split("=");return[].concat(t,[["AbTesting::enter",{experiment:a[0],variation:a[1]}]])},[]).forEach(t=>{_paq.push(t)});var g=d.createElement('script'), s=d.getElementsByTagName('script')[0];g.async=true; g.src='//cdn.matomo.cloud/pretto.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);})();`,
        }}
      />
      <NavigationLinkProvider {...navigationLinkProps}>
        <ConsentManagerContext.Provider value={{ isCookieBannerActive, onShowCookieBanner }}>
          {consentManager}
        </ConsentManagerContext.Provider>
      </NavigationLinkProvider>
    </>
  )
}

ConsentManagerView.propTypes = {
  location: PropTypes.object.isRequired,
  getCookieBarProps: PropTypes.func.isRequired,
}

export default memo(ConsentManagerView)
